<template>
  <div>
    <my-nav-bar
      title="记录详情"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container list">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="(transGoods, index) in list" :key="index" class="goods">
        <div>
          <van-image :src="transGoods.image" class="goods-image" />
        </div>
        <div class="goods-info">
          <div class="goods-name">{{ transGoods.name }}</div>
          <div class="goods-size">规格：1 * {{ transGoods.size }}</div>
          <div class="goods-quantity">
            <span>数量: </span>
            <span v-if="transGoods.large_volume">
              {{ transGoods.large_volume }}{{ transGoods.large_unit }}
            </span>
            <span v-if="transGoods.least_volume">
              {{ transGoods.least_volume }}{{ transGoods.least_unit }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTransCheckRecordDetail } from '@/api/trans_check'
import myNavBar from '@/components/my-nav-bar'
export default {
  components: { myNavBar },
  data() {
    return {
      list: [],
      showEmpty: false,
      transOrderId: this.$route.query.trans_order_id,
      orderId: this.$route.query.order_id
    }
  },
  created() {
    getTransCheckRecordDetail(this.transOrderId).then(res => {
      this.list = res.data
      this.showEmpty = this.list.length === 0
    })
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
  }
  .goods {
    margin-bottom: 10px;
    background-color: #fff;
    display: flex;
    padding: 10px;
  }
  .goods-info {
    margin-left: 10px;
  }
  .goods-image {
    width: 80px;
    height: 80px;
  }
  .goods-name {
    font-size: 15px;
  }
  .goods-size, .goods-quantity {
    font-size: 13px;
    color: #989898;
    margin-top: 3px;
  }
</style>
